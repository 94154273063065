.loader {
    animation: rotate 1s infinite;
    height: 40px;
    width: 40px;
  }

  .loader:before,
  .loader:after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 20px;
    width: 20px;
  }
  .loader:before {
    animation: ball1 1s infinite;
    background-color: #E3194A ;
    box-shadow: 30px 0 0 #357A7A;
    margin-bottom: 10px;
  }
  .loader:after {
    animation: ball2 1s infinite;
    background-color: #357A7A;
    box-shadow: 30px 0 0 #E3194A ;
  }

  @keyframes rotate {
    0% { transform: rotate(0deg) scale(0.8) }
    50% { transform: rotate(360deg) scale(1.2) }
    100% { transform: rotate(720deg) scale(0.6) }
  }

  @keyframes ball1 {
    0% {
      box-shadow: 30px 0 0 #CC9A37 ;
    }
    50% {
      box-shadow: 0 0 0 #CC9A37 ;
      margin-bottom: 0;
      transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #CC9A37 ;
      margin-bottom: 10px;
    }
  }

  @keyframes ball2 {
    0% {
      box-shadow: 30px 0 0 #72BE74;
    }
    50% {
      box-shadow: 0 0 0 #72BE74;
      margin-top: -20px;
      transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #72BE74;
      margin-top: 0;
    }
  }